import React, { Component, createRef } from 'react';
import '../assets/css/skills.css';
import skillsDomains from '../assets/data/skills-domains.json';
import skillsLists from '../assets/data/skills-list.json';

class Skills extends Component {
  constructor(props) {
    super(props);
    this.skillsRef = createRef();
  }

  componentDidMount() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.skillsRef.current.classList.add('visible');
          } else {
            this.skillsRef.current.classList.remove('visible');
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (this.skillsRef.current) {
      observer.observe(this.skillsRef.current);
    }
  }

  render() {
    return (
      <>
        <div className='skills' id="skills" ref={this.skillsRef}>
          <div className='head'>
            <h1>Comptétences</h1>
            <h4>Une expertise à votre disposition pour réaliser vos projets</h4>
            <hr></hr>
          </div>
          <div className='skills-cat'>
            <div className='list'>
              <h4>{skillsDomains.title}</h4>
              <ul>
                {skillsDomains.items.map((domain, index) => (
                  <li key={index}>
                    <h5><i className="fa-solid fa-circle-check"></i> {domain.title}</h5>
                    <p>{domain.description}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='skills-list'>
            {Object.keys(skillsLists).map((key) => (
              <div className={key} key={key}>
                <h4>{skillsLists[key].title}</h4>
                <ul>
                  {skillsLists[key].items.map((skill, index) => (
                    <li key={index}>
                      <i className="fa-solid fa-circle"></i> <span>{skill}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }
}

export default Skills;

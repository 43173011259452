import React, { Component } from 'react';
import '../assets/css/footer.css';
import url from '../assets/data/url.json';
import contact from '../assets/data/contact.json';

class Footer extends Component {
  render() {
    return (
      <>
        <div className='footer'>
          <section className='network'>
              <ul>
                <img src="../assets/img/logo.png" alt="Logo" className='nav-logo'/>
                {Object.entries(url).map(([key, { url, icon }]) => (
                  <li key={key}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      <i className={icon}></i>
                    </a>
                  </li>
                ))}
              </ul>
          </section>

          {/* First */}

          <section className='first'>
            <div className='sub-first'>
              <div className='info'>
                <h4>Développement Web Freelance</h4><hr></hr>
                <p>
                  Transformez vos idées en réalité digitale avec un développeur freelance passionné et engagé.
                  Des solutions sur mesure, conçues pour répondre à vos besoins et objectifs.
                  Alliez performance et innovation pour donner vie à vos projets web.
                </p>
              </div>
              <div className='contact'>
                <h4>Contact</h4><hr></hr>
                <ul>
                  {Object.entries(contact).map(([key, { value, icon, link }]) => (
                    <li key={key}>
                      <i className={icon}></i>
                      <a href={link}> {value}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>

          {/* Second */}
          
          <section className='second'>
            <div className='sub-second'>
              JW-STUDIO © 2024
            </div>
            <div className='sub-second'>
                <a href="http://jw-studio.fr:/mentions-legales">Mentions légales </a> ▪
            </div>
            <div className='sub-second'>
              <small> JORDAN Charly</small>
            </div>
          </section>
        </div>
      </>
    )
  }
}

export default Footer;
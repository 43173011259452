import React, { Component } from 'react';
import '../assets/css/social.css';
import urls from '../assets/data/url.json';
import socials from '../assets/data/social.json';

class Social extends Component {
  render() {
    return (
      <>
        <div className='social' id="social">

          {/* URL */}

          <div className='social-icon'>
            {Object.entries(urls).map(([key, { url, icon }]) => (
              <a key={key} href={url} target="_blank" rel="noopener noreferrer">
                <i className={icon}></i>
              </a>
            ))}
          </div>

          {/* Contact */}

          <div className='social-contact'>
            {Object.entries(socials).map(([key, { value, icon, link }]) => (
              <a key={key} href={link}>
                <i className={icon}></i> <span>{value}</span>
              </a>
            ))}
          </div>
        </div>
      </>
    )
  }
}

export default Social;

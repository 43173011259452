import React, { Component, createRef } from 'react';
import '../assets/css/service.css';
import services from '../assets/data/service.json';

class Service extends Component {
  constructor(props) {
    super(props);
    this.skillsRef = createRef();
  }

  componentDidMount() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.skillsRef.current.classList.add('visible');
          } else {
            this.skillsRef.current.classList.remove('visible');
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (this.skillsRef.current) {
      observer.observe(this.skillsRef.current);
    }
  }

  render() {
    return (
      <>
        <div className='service' id="services" ref={this.skillsRef}>
          <div className='head'>
            <h1>Services</h1>
            <h4>Des services sur mesure pour répondre à vos attentes</h4>
            <hr></hr>
          </div>

          {/* Service left */}
          
          <div className='service-cat service-left'>
            {services.filter(service => service.alignment === 'left').map((service, index) => (
              <div key={index}>
                <div>
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                </div>
                <div>
                  <i className={service.icon}></i>
                </div>
              </div>
            ))}
          </div>

          {/* Service right */}

          <div className='service-cat service-right'>
            {services.filter(service => service.alignment === 'right').map((service, index) => (
              <div key={index}>
                <div>
                  <i className={service.icon}></i>
                </div>
                <div>
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default Service;